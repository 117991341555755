import {createSlice} from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cartReducer",
    initialState: {
        cartCount: 0,
        cartLoaderLoading: false,
        cartData: [],
        currentItem: null,
        deliveryAddressList: [],
        defaultDeliveryAddress: {},
        currentEditableAddress: {}
    },
    reducers: {
        incrementCart(state, action) {
            state.cartCount++;
        },
        setCartValue(state, action) {
            state.cartCount = action.payload;
        },
        addToCart(state, action) {
            state.cartData = action.payload
            // Set Total Count
            state.cartCount = state.cartData.reduce(function (sum, current) {
                return sum + Number(current.ordered_quantity);
            }, 0)
        },
        setCartLoaderLoading(state, action) {
            state.cartLoaderLoading = action.payload;
        },
        removeFromCart(state, action) {
            state.cartData = state.cartData.filter((item) => item.product_id !== action.payload.product_id)

            // Set Total Count
            state.cartCount = state.cartData.reduce(function (sum, current) {
                return sum + Number(current.ordered_quantity);
            }, 0)
        },
        loadCurrentItem(state, action) {
            state.currentItem = action.payload
        },
        adjustQuantity(state, action) {
            state.cartData = state.cartData.map((item) =>
                item.product_id === action.payload.product_id
                    ? {...item, ordered_quantity: +action.payload.quantity}
                    : item
            )

            // Set Total Count
            state.cartCount = state.cartData.reduce(function (sum, current) {
                return sum + Number(current.ordered_quantity);
            }, 0)
        },
        clearCart(state, action) {
            state.cartData = [];
            state.cartCount = 0;
        },
        setDeliveryAddressList(state, action) {
            state.deliveryAddressList = action.payload;
        },
        setDefaultDeliveryAddress(state, action) {
            state.defaultDeliveryAddress = action.payload;
        },
        setCurrentEditableAddress(state, action) {
            state.currentEditableAddress = action.payload
        }
    }
});

export const cartAction = cartSlice.actions;

export default cartSlice;
