import {membershipOptions as mO, membershipOptionsPre as mOpre, membershipValues as mV} from "../../helper/utils";
export const textRegex = /^[a-zA-Z ]+$/;
export const regexContainAtleastOneChar = /^(?=.*[a-zA-Z]).+/;
export const regexContainAtleastThreeChar = /^(?=.*[a-zA-Z]{3,}).+/;
export const RegistrationNameRegex = /^[a-zA-Z \-\.]+$/;
export const RegistrationNameRegexHumanForm = "Alphabet, hyphen , dot and space";
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{6,})/;
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const userTypeValues = {
    EXISTING_USER: "old_user",
    NEW_USER: "new_user",
};

export const userTypes = [
    { label: "Current OLsA Member", value: userTypeValues.EXISTING_USER },
    { label: "Applying for Membership", value: userTypeValues.NEW_USER },
];

const users = ["AD1900001"];

export const getIntroducerOptions = () => {
    return users.map((user) => ({label: user, value: user}));
};

export const membershipValues = mV

export const allowedMemberShipForPayment = [membershipValues.LIFETIME_MEMBER, membershipValues.GENERAL_MEMBER];

export const membershipOptions = mO
export const membershipOptionsPre = mOpre

export const membershipOptionsBatchYear = [
    membershipValues.LIFETIME_MEMBER,
    membershipValues.GENERAL_MEMBER,
    membershipValues.STUDENT,
    membershipValues.COLLEGIATE_LAB,
];

export const getLabelForSSCBatch = (membershipType) => {
    // console.log("membershipType", membershipType);
    let batchLabel = "SSC Batch";

    if (membershipOptionsBatchYear.includes(membershipType) || membershipType === "") {
        batchLabel = "SSC Batch";
    } else {
        batchLabel = "Birth Year";
    }
    return batchLabel;
};

export const monthNames = [
    { label: "January", value: 31 },
    { label: "February", value: 29 },
    { label: "March", value: 31 },
    { label: "April", value: 30 },
    { label: "May", value: 31 },
    { label: "June", value: 30 },
    { label: "July", value: 31 },
    { label: "August", value: 31 },
    { label: "September", value: 30 },
    { label: "October", value: 31 },
    { label: "November", value: 30 },
    { label: "December", value: 31 },
];

export const piGroup = ["Science", "Arts", "Commerce"];
export const bloodGroup = ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"];

// export const membershipOptions = [
//   {label: "General Member", value: "general_member"},
//   {label: "Lifetime Member", value: "lifetime_member"},
// ]

// Al-Beruni
// Al-Mamun
// Omar Khayyam
// Salahuddin

export const schoolHouseOptions = [
    { label: "Al-Beruni", value: "Al-Beruni" },
    { label: "Al-Mamun", value: "Al-Mamun" },
    { label: "Omar Khayyam", value: "Omar Khayyam" },
    { label: "Salahuddin", value: "Salahuddin" },
];

export const generateYearOptions = (intervalInYear, toYear) => {
    const years = Array.from(new Array(intervalInYear), (val, index) => Math.abs(index - toYear).toString());
    return years.map((y) => ({ label: y, value: y }));
};

export const relationshipStatusValues = {
    SINGLE: "single",
    MARRIED: "married",
};

export const operations = {
    ADD: "add",
    UPDATE: "update",
};

export const relationshipStatusOptions = [
    {
        label: "Single",
        value: relationshipStatusValues.SINGLE,
    },
    {
        label: "Married",
        value: relationshipStatusValues.MARRIED,
    },
];

export const spouseProfessionStatusValues = {
    HOUSEWIFE: "house_wife",
    EMPLOYED: "employed",
};
export const spouseProfessionStatusValuesToLabel = {
    "house_wife": "House Wife",
    "employed": "Employed",
};

export const spouseProfesionStatus = [
    {
        label: "House Wife",
        value: spouseProfessionStatusValues.HOUSEWIFE,
    },
    {
        label: "Employed",
        value: spouseProfessionStatusValues.EMPLOYED,
    },
];

const childrenSexStatusValues = {
    MALE: "male",
    FEMALE: "female",
};

export const childrenSexStatus = [
    {
        label: "Male",
        value: childrenSexStatusValues.MALE,
    },
    {
        label: "Female",
        value: childrenSexStatusValues.FEMALE,
    },
];

export const userStatusValue = {
    APPROVED: "approved",
    IN_REVIEW: "in_review",
    PENDING: "pending",
    REJECTED: "rejected",
};

export const extractYear = (date) => {
    // alert(preRegisterValue.duration_start)
    return new Date(date).getFullYear();
};

export const changeMembershipFrom= [
    {label:"Non Member",value: membershipValues.NON_MEMBER},
    { label: "General Member", value: membershipValues.GENERAL_MEMBER}
]
export const changeMembershipTo= [
    { label: "General Member", value: membershipValues.GENERAL_MEMBER},
    { label: "Life Time Member", value: membershipValues.LIFETIME_MEMBER}
]
export const changeMembershipTo2= [
    { label: "Life Time Member", value: membershipValues.LIFETIME_MEMBER}
]
