const baseLocation = require('./base_location');

module.exports = {
    host: 'localhost',
    port: 3001,
    DEFAULT_LANGUAGE: 'en', // for english en and for japanese use ja
    API_URL: baseLocation,
    aws: baseLocation.aws,
    payment: baseLocation.payment
};
