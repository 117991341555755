import React, {lazy, Suspense} from "react";
import CustomPageLoader from "../../../components/customPageLoader";
import {Switch} from "react-router-dom";
import requireLogin from "../routerControl";
import {GuardProvider, GuardedRoute} from 'react-router-guards';
import Redirect from "react-router-dom/es/Redirect";

import {Header} from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import path from "../../../routes/path";

import './static/styles.scss'

const LoginPage = lazy(() => import('../../../app/login/LoginPage'));
const SocialLoginIntermediaryPage = lazy(() => import('../../../app/login/SocialLoginIntermediaryPage'));
const PreRegistration = lazy(() => import('../../../app/registration/PreRegistration'));
const RegistrationConfirmationPage = lazy(() => import('../../../app/registration/RegistrationConfirmationPage'));
const ConfirmSignupPageEmail = lazy(() => import('../../../app/registration/ConfirmSignupPageEmail'));
const ConfirmSignupPagePhone = lazy(() => import('../../../app/registration/ConfirmSignupPagePhone'));
const ForgetPasswordPage = lazy(() => import('../../../app/forget-password/ForgetPasswordPage'));
const ForgetPasswordConfirmPage = lazy(() => import('../../../app/forget-password/ForgetPasswordConfirmPage'));
const ForgotPasswordPhoneConfirmationPage = lazy(() => import('../../../app/forget-password/ForgotPasswordPhoneConfirmationPage'));
const PasswordResetPage = lazy(() => import('../../../app/password-reset/PasswordResetPage'));
const CompleteRegistration = lazy(() => import('../../../app/registration/Registration'));

const routes = [
    {
        accessRequired: "no",
        path: path.login,
        exact: true,
        component: LoginPage,
    },

    {
        accessRequired: 'no',
        path: path.social_redirect_url,
        component: SocialLoginIntermediaryPage,
    },

    {
        accessRequired: "no",
        path: path.register,
        exact: true,
        component: PreRegistration,
    },

    {
        accessRequired: "no",
        path: path.registration_confirmation,
        exact: true,
        component: RegistrationConfirmationPage,
    },
    {
        accessRequired: "no",
        path: path.confirm_signup,
        exact: true,
        component: ConfirmSignupPageEmail,
    },
    {
        accessRequired: "no",
        path: path.registration_phone_confirmation,
        exact: true,
        component: ConfirmSignupPagePhone,
    },

    {
        accessRequired: "no",
        path: path.forgot_password,
        component: ForgetPasswordPage,
    },
    {
        accessRequired: "no",
        path: path.forgot_password_phone_confirm,
        component: ForgotPasswordPhoneConfirmationPage,
    },

    {
        accessRequired: "no",
        path: path.reset_password_phone,
        component: PasswordResetPage,
    },
    {
        accessRequired: "no",
        path: path.reset_password,
        component: PasswordResetPage,
    },
    {
        accessRequired: "no",
        path: path.forgot_password_confirm,
        component: ForgetPasswordConfirmPage,
    },
    {
        accessRequired: "yes", // both?
        path: path.complete_registration,
        exact: false,
        component: CompleteRegistration,
    }
];


export default ({props}) => {
    // console.log("render default layout");

    return (
        <>
            <Header/>

            <div className="default-layout-main">
                <Suspense fallback={<h4 className="height-450">Loading . . .</h4>} /*fallback={<CustomPageLoader pageLoader={true}/>}*/>
                    <GuardProvider guards={[requireLogin]} /*loading={Loading}*/ error={<h1>Not allowed</h1>}>
                        <Switch>
                            {routes.map((route, i) =>
                                <GuardedRoute
                                    key={i}
                                    path={route.path}
                                    exact={route.exact}
                                    name={`${route.path}`}
                                    component={route.component}
                                    meta={{auth: route.accessRequired}}
                                />
                            )}
                            <Redirect to={path.error_404}/>
                        </Switch>
                    </GuardProvider>
                </Suspense>
            </div>

            <Footer/>
        </>
    );
};
