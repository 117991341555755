const ApiUrlServices = {
    SIGN_IN: "/auth/authentication/signin",
    SIGN_UP1: "/auth/authentication/signup1",
    SIGN_OUT: "/auth/authentication/global-logout",
    REGISTER: "/auth/authentication/register",
    FORGOT_PASSWORD: "/auth/authentication/forgot-password",
    GET_CONFIRMATION_CODE_VALIDITY: "/auth/authentication/get-confirmation-code-validity",
    SET_NEW_PASSWORD: "/auth/authentication/set-password",
    CHANGE_PASSWORD: "/auth/authentication/change_password",
    USER_DETAILS: (userId) => `/auth/users/${userId}`,
    USER_DETAILS_NEW: (userId) => `/profile/users/profile/${userId}`,
    USER_DETAILS_NEW_SEARCH: (userId, search_section) => `/profile/users/profile/${userId}?query=${search_section}`,
    GET_BATCH_USERS: "/auth/users/batch_users",
    PROFILE_UPDATE: "/profile/users/profile_update",
    PROFILE_ADD_FAMILY: "/profile/users/add_family",
    PROFILE_UPDATE_FAMILY: "/profile/users/update_family",
    PROFILE_ADD_SPOUSE: "/profile/users/add_spouse",
    PROFILE_UPDATE_SPOUSE: "/profile/users/update_spouse",
    PROFILE_ADD_CHILD: "/profile/users/add_child",
    PROFILE_UPDATE_CHILD: "/profile/users/update_child",

    APPROVAL_USER_SIGNUP: "/auth/authentication/approval-user-signup",
    USER_PAYMENT: "/payment/users/checkout-cart",
    REGISTRATION_CONFIRM_SIGNUP: "/auth/authentication/confirm-signup",
    RESEND_CONFIRMATION_CODE: "/auth/authentication/resend-confirmation-code",
    SOCIAL_SIGN_IN: "/auth/authentication/social-signin",
    SOCIAL_OAUTH_TOKEN_URL: "/oauth2/token",

    /*StayWithUs*/
    PROFILE_ADD_CONTACT: "/profile/users/add_contact",
    PROFILE_UPDATE_CONTACT: "/profile/users/update_contact",

    GET_ADDRESS_COMMON_SEARCH: "/profile/users/search_attributes",
    ADD_ADDRESS: "/profile/users/add_address",
    UPDATE_ADDRESS: "/profile/users/update_address",
    ADDRESS_LIST: (userId) => `/profile/users/list_address/${userId}`,

    ADD_USER_EDUCATION: "/profile/users/add_user_education",
    UPDATE_USER_EDUCATION: "/profile/users/update_user_education",

    UPDATE_USER_PROFILE_PHOTO: (userId) => `/profile/users/change_picture/${userId}`,

    // COMMON_SEARCH_URL: (search_in, search_string, parent_search_string) => `/profile/users/search_attributes?search_in=${search_in}&search_string=${search_string}`,
    COMMON_SEARCH_URL: (search_in, search_string, parent_search_string) => `/profile/users/search_attributes?search_in=${search_in}&search_string=${search_string}&parent_search_string=${parent_search_string}`,

    GET_COMMON_SEARCH: "/profile/users/search_attributes",

    // ProfessionalSummary
    GET_PROFESSIONAL_SUMMARY: (userId) => `/profile/users/list_user_experience/${userId}`,
    ADD_PROFESSIONAL_SUMMARY: "/profile/users/add_user_experience",
    UPDATE_PROFESSIONAL_SUMMARY: "/profile/users/update_user_experience",
    ADD_VOLUNTARY: "/profile/users/add_voluntary",
    UPDATE_VOLUNTARY: "/profile/users/update_voluntary",
    PROFILE_USERS_SEARCH: "/profile/users/profile_search",
    DELETE_PROFESSIONAL_SUMMARY: (id) => `/profile/users/delete_user_experience/${id}`,
    DELETE_EDUCATION: (id) => `/profile/users/delete_user_education/${id}`,
    DELETE_VOLUNTARY: (id) => `/profile/users/delete_voluntary/${id}`,
    DELETE_SPOUSE: (id) => `/profile/users/delete_spouse/${id}`,
    DELETE_CHILDREN: (id) => `/profile/users/delete_child/${id}`,


    //    get Category
    GET_CATEGORIES: (limit, offset) => `/forum/get-categories/${limit}/${offset}`,
    GET_SEARCH_USER: `/auth/users/search-get-user`,
    ADD_FORUM_POST: `/forum/add-forum-post`,
    EDIT_FORUM_POST: `/forum/update-post`,
    GET_FORUM_POST: (cat_id) => `/forum/get-forum-posts?cat_id=${cat_id}`,
    GET_FORUM_POST_WITH_ID: (post_id) => `/forum/get-forum-post/${post_id}`,
    GET_FORUM_POST_BY_USER: (user_id, cat_id) => `/forum/get-user-posts/${user_id}?cat_id=${cat_id}`,
    GET_FORUM_POST_BY_USER_TAGGED: (user_id, cat_id) => `/forum/get-user-tag-posts/${user_id}?cat_id=${cat_id}`,
    GET_FORUM_COMMENT_POST: (post_id) => `/forum/get-comments/${post_id}`,
    ADD_FORUM_POST_COMMENT: `/forum/add-comment-to-post`,
    EDIT_FORUM_POST_COMMENT: `/forum/update-comment`,
    DELETE_POST: `/forum/delete-post`,
    DELETE_COMMENT: `/forum/delete-comment`,

    GET_PRODUCT_CATEGORIES: `/product/get-product-categories`,
    GET_PRODUCT_LIST: `/product/get-product-list`,
    GET_PRODUCT_DETAILS:"/product/get-product-details",
    ADD_TO_CART: `/order/add-product-cart`,
    UPDATE_IN_CART: `/order/update-product-cart`,
    CHECKOUT_CART: `/payment/users/checkout-cart`,
    ORDER_LIST: `/order/order_list/`,
    ORDER_DETAILS :(orderId) => `/order/order_details/${orderId}`,
    GET_CART_LIST: (user_id) => `/order/get-cart-info/${user_id}`,
    DELETE_FROM_CART: (user_id, product_id) => `/order/remove-product-from-cart/${user_id}/${product_id}`,

    ADD_DELIVERY_ADDRESS: `/order/add-order-address`,
    UPDATE_DELIVERY_ADDRESS: `/order/edit-order-address`,
    LIST_DELIVERY_ADDRESS: `/order/list-order-address`,

    FEE_LIST: "/config/get-client-config",
    CHECKOUT_MEMBERSHIP: "/payment/users/checkout-membership-change-fee",
};

export default ApiUrlServices;
