import base_location from "./base_location.json"

export const S3_Pool_conf = {
    Auth: {
        identityPoolId: base_location.identityPoolId,
        region: base_location.region,
        userPoolId: base_location.userPoolId,
        userPoolWebClientId: base_location.aws.cognito_app_client_id
    },
    Storage: {
        AWSS3: {
            bucket: base_location.bucket,
            region: base_location.region,
            // REQUIRED Amazon Cognito Identity Pool ID
            identityPoolId: base_location.identityPoolId
        }
    }
};

