import React,{lazy,Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import path from "./path";
import CustomPageLoader from '../components/customPageLoader'
import TheDefaultLayout from "../containers/layouts/DefaultLayout/TheDefaultLayout";
import TheAuthLayout from "../containers/layouts/AuthLayout/TheAuthLayout";
import TheForumLayout from "../containers/layouts/TheForumLayout/TheForumLayout";
import Error404 from "../app/error-pages/404";

export const MainRoutes = () => {
    return (
        <Router>
            <Suspense fallback={<CustomPageLoader pageLoader={true}/>}>
                <Switch>
                    <Route path={path.error_404} exact component={Error404}/>
                    <Route path={path.auth} name="Auth Layout" render={props => <TheAuthLayout {...props} />} />
                    <Route path={path.forum} name="Forum Layout" render={props => <TheForumLayout {...props} />} />
                    <Route path={path.home} name="Default Layout" render={props => <TheDefaultLayout {...props} />} />
                </Switch>
            </Suspense>
        </Router>
    );
};

