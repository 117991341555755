import {createSlice} from "@reduxjs/toolkit";

const productSlice = createSlice({
    name: "productReducer",
    initialState: {
        categories: [],
        currentItem: null
    },
    reducers: {
        setCategories(state, action) {
            state.categories = action.payload;
        },
        setProducts(state, action) {
            state.products = action.payload;
        },
        loadCurrentItem(state, action) {
            state.currentItem = action.payload
        },
    }
});

export const productAction = productSlice.actions;

export default productSlice;
