import React from 'react';
import './static/ElementLoader.scss';

function ElementLoader(props) {
    return (
                props.loader ?
                <div className="" style={{
                    zIndex: "10000",
                    inset: "0px 0px 0px",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 0.4)"
                }}>
                    <div style={{
                        position: props.fixed ? "fixed" : "absolute",
                        top: `50%`,
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)"
                    }}>
                        <div className='spinner'>
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>

                </div>
                : null
    );
}

export default ElementLoader;