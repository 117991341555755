import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiUrlServices from "../../networks/ApiUrlServices";
import AxiosServices from "../../networks/AxiosService";

export const fetchLogin = createAsyncThunk(
    "auth/fetchLoginLoading",
    async (data) => {
        try {
            // return await axios.post(ApiUrlServices.SIGN_IN, data);
            return await AxiosServices.post(
                ApiUrlServices.SIGN_IN,
                data,
                false
            );
        } catch (e) {
            console.log("---------error from store---------");
            console.log(e);
            console.log("---------error from store---------");
            return e;
        }
    }
);

const loginInitialState = {
    status: "idle",
    data: {},
    error: {},
};

const loginSlice = createSlice({
    name: "loginData",
    initialState: loginInitialState,
    reducers: {},
    extraReducers: {
        [fetchLogin.pending.type]: (state, action) => {
            state = {
                status: "loading",
                data: action.payload,
                error: {},
            };
        },
        [fetchLogin.fulfilled.type]: (state, action) => {
            state = {
                status: "idle",
                data: action.payload,
                error: {},
            };
        },
        [fetchLogin.rejected.type]: (state, action) => {
            state = {
                status: "idle",
                data: action.payload,
                error: action.payload,
            };
        },
    },
});

export default loginSlice.reducer;
