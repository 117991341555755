import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import path from "../../routes/path";
import config from "../../config";
import {useDispatch} from "react-redux";
import {addPreRegisterData, clearPreRegisterData} from "../../store/auth/pre_registration";
import {userAction} from "../../store/userSlice";
import {cartAction} from "../../store/cartSlice";

const useAuth = () => {
    const [pageLoader, setPageLoader] = useState(false);
    const [logoutLoader, setLogoutLoader] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false)
    const [isProfile, setIsProfile] = useState(false)
    const [isAuthLoading, setAuthLoading] = useState(true)
    const [userData, setUserData] = useState({})
    const [responseData, setResponseData] = useState({})
    const history = useHistory();
    const dispatch = useDispatch();

    const load_data = async (sessionUserData) => {
        // setLoading(true);
        if (!loggedIn) {
            setPageLoader(true);
            AxiosServices.get(
                ApiUrlServices.USER_DETAILS(sessionUserData.userId)
            ).then((res) => res.data.data)
                .then(response => {
                    console.log(response);
                    const goToProfile = response && !response.isAdminApprovalPending && !response.isIntroducerApprovalPending;
                    setResponseData(response);
                    dispatch(userAction.setUserDetailsValue(response))
                    // dispatch(addPreRegisterData(...response));
                    console.log(responseData);
                    console.log(goToProfile);
                    setIsProfile(goToProfile);

                    // console.log(goToProfile);
                })
                .catch(async err => {
                    console.log(err);
                    await logout();
                    history.push(path.login);
                })
                .finally(() => {
                    setPageLoader(false);
                    setAuthLoading(false);

                });
        }
    }
    const mock_load_data = (sessionUserData) => {
        // console.log(JSON.stringify(sessionUserData, null, 2));
        setPageLoader(true);
        const gotoProfile = !(sessionUserData.isAdminApprovalPending || sessionUserData.isIntroducerApprovalPending);
        setIsProfile(gotoProfile);
        setPageLoader(false);
        setAuthLoading(false);
    }


    useEffect(() => {
        let sessionUserData = JSON.parse(localStorage.getItem('userData'));
        if (sessionUserData === undefined || sessionUserData === null) {
            setLoggedIn(false)
            setUserData({})
        } else {
            setLoggedIn(true)
            setUserData(sessionUserData)
            load_data(sessionUserData);

        }


    }, [isProfile]);

    async function logout() {
        setPageLoader(true)
        setLogoutLoader(true)
        localStorage.clear();
        dispatch(clearPreRegisterData())
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        let success = false;
        if (userData['signinSource'] === 'REGULAR') {
            let payload = {
                accessToken: userData.accessToken
            }

            await AxiosServices.post(ApiUrlServices.SIGN_OUT, payload, false)
                .then((response) => {
                    console.log(response);
                    setLoggedIn(false);
                    setPageLoader(false);
                    setLogoutLoader(false);
                    success = true;
                    history.push(
                        {pathname: path.home}
                    )
                })
                .catch((error) => {
                    console.log(error)
                    setLoggedIn(false);
                    setPageLoader(false);
                    setLogoutLoader(false);
                    history.push(
                        {pathname: path.home}
                    )
                })
        } else {
            setLoggedIn(false);
            localStorage.clear();
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });

            if (userData['signinSource'] === 'AUTH0') {
                window.location.href =
                    config.API_URL.social_api_base_url +
                    `/logout?client_id=` +
                    config.aws.cognito_app_client_id +
                    `&logout_uri=` +
                    config.API_URL.api_base_url +
                    `/auth/authentication/linkedin-logout/` +
                    config.aws.auth0_app_client_id +
                    `/` + config.API_URL.auth0_redirected_endpoint + `/`
            } else {
                window.location.href =
                    config.API_URL.social_api_base_url +
                    `/logout?client_id=` +
                    config.aws.cognito_app_client_id +
                    `&logout_uri=` +
                    config.API_URL.web_base_url +
                    path.login + '/';
            }
            success = true;
        }

        if (success){
            dispatch(cartAction.clearCart())
            dispatch(cartAction.setDefaultDeliveryAddress({}))
        }

    }

    return {pageLoader, logoutLoader, loggedIn, isProfile, logout, userData, isAuthLoading, responseData};
};

export default useAuth;
