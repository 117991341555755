import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {MainRoutes} from "./routes/index";
import {Provider} from "react-redux";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import "./index.css";
import "./scss/global.scss";
import "./scss/responsive.scss"
import "react-toastify/dist/ReactToastify.css";

import store from "./store";
import path from "./routes/path";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import axios from "axios";
import Amplify from "aws-amplify";
import {S3_Pool_conf} from "./config/aws-amplify";
import {API_URL} from "./config";
import {toast} from "react-toastify";

Amplify.configure(S3_Pool_conf);
let persistor = persistStore(store);
toast.configure();

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        let usersData = JSON.parse(localStorage.getItem("userData"));
        const refreshToken = usersData.refreshToken;
        let refreshTokenExpired = true;
        return axios
            .post(
                `${API_URL.api_base_url}/auth/authentication/refresh_token`,
                JSON.stringify({
                    refresh_token: refreshToken,
                })
            )
            .then((response) => {
                usersData["accessToken"] = response.data.data.accessToken;
                usersData["idToken"] = response.data.data.idToken;

                localStorage.setItem("userData", JSON.stringify(usersData));

                error.response.config.headers[
                    "Authorization"
                    ] = `Bearer ${response.data.data.idToken}`;

                refreshTokenExpired = false;
                return axios(error.response.config);
            })
            .catch((error) => {
                if (refreshTokenExpired) {
                    localStorage.clear();
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c
                            .replace(/^ +/, "")
                            .replace(
                                /=.*/,
                                "=;expires=" + new Date().toUTCString() + ";path=/"
                            );
                    });
                    window.location = path.login;
                }else {
                    return Promise.reject(error);
                }
            });
    }
);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <MainRoutes/>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
