import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {cartAction} from "../../store/cartSlice";
import "./static/MyCart.css"
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import ButtonLoader from "../buttonLoader";
import {toast} from "react-toastify";
import path from "../../routes/path";

const MyCart = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const cartCount = useSelector(state => state.cartReducer.cartCount);
    const cartData = useSelector(state => state.cartReducer.cartData);
    const userDetails = useSelector(state => state.userReducer.userDetails);
    console.log(userDetails)

    const [totalPrice, setTotalPrice] = useState(0);
    const [cartAddClass, setCartAddClass] = useState(false)
    const [removeItemLoader, setRemoveItemLoader] = useState(false)
    const [adjustQtyLoader, setAdjustQtyLoader] = useState(false)
    const [showCartInfo, setShowCartInfo] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const userData = JSON.parse(localStorage.getItem('userData'));

    // Hide cart based on pages...
    useEffect(()=>{
        (location.pathname === path.event || location.pathname === path.product || location.pathname === path.checkout || location.pathname === path.event_details || location.pathname === path.product_details) ? setShowCartInfo(true) : setShowCartInfo(false)
    }, [location.pathname])

    const handleQuantityChange = (item, type) => {
        if (type === 'decrement') {
            if (item.ordered_quantity > 1) {
                updateCartQuantity(Number(item.ordered_quantity) - 1, item)
            }
        }
        if (type === 'increment') {
            if (item.ordered_quantity < 1000) {
                updateCartQuantity(Number(item.ordered_quantity) + 1, item)
            }
        }
    }

    const updateCartQuantity = (adjQty, item) => {
        setAdjustQtyLoader(true)
        setCurrentItem(item)
        let payload = {
            category_id: item.category_id,
            product_id: item.product_id,
            product_name: item.product_name,
            ordered_quantity: adjQty,
            user_id: userData.userId
        }
        AxiosServices.post(ApiUrlServices.UPDATE_IN_CART, payload).then(res => {
            console.log(res)
            toast.success('Quantity updated successfully')
            dispatch(cartAction.adjustQuantity(
                {
                    'product_id': item.product_id,
                    'quantity': adjQty
                }))
        }).catch(err => {
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(() => {
            setAdjustQtyLoader(false)
        })
    }

    const removeFromCart = (item) => {
        setRemoveItemLoader(true)
        setCurrentItem(item)
        AxiosServices.remove(ApiUrlServices.DELETE_FROM_CART(userData.userId, item.product_id)).then(res => {
            toast.success('Item successfully removed')
            dispatch(cartAction.removeFromCart(item))
        }).catch(err => {
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(() => {
            setRemoveItemLoader(false)
        })
    }

    const getCartList = () => {
        AxiosServices.get(ApiUrlServices.GET_CART_LIST(userData.userId)).then(res => {
            console.log(res.data.data.user_cart_products)
            dispatch(cartAction.addToCart(res.data.data.user_cart_products))
        }).catch(err => {
            console.log(err)
        }).finally(() => {

        })
    }

    useEffect(() => {
        getCartList()
    }, [])

    useEffect(() => {
        let price = 0;
        cartData.forEach((item) => {
            price += item.ordered_quantity * item.ordered_price;
        });

        setTotalPrice(parseFloat(price).toFixed(2))

    }, [cartCount])

    const redirectToCheckout = () => {
        if (cartData.length <= 0) {
            return
        }
        setCartAddClass(false)
        history.push(path.checkout)
    }

    return (
        <div>
            {!userDetails.isAdminApprovalPending && !userDetails.isIntroducerApprovalPending && showCartInfo && (
                <div className="cart-button-sidebar">
                    <div className="cart-button-sidebar-button" onClick={() => setCartAddClass(true)}>
                        <div className="icon">
                            <i className="fa fa-shopping-bag" aria-hidden="true"/>
                            <span>{cartCount} ITEMS</span>
                        </div>
                        <div className="conent">
                            <strong>BDT {totalPrice}</strong>
                        </div>
                    </div>
                </div>
            )
            }

            <div className={cartAddClass ? 'mini-cart-area active' : 'mini-cart-area'}>
                <div className="mini-cart-full">
                    <div className="mini-cart-header">
                        <h2><i className="fa fa-shopping-bag" aria-hidden="true"/>
                            <span>{cartCount} Items</span></h2>
                        <div className="mini-cart-close" onClick={() => setCartAddClass(false)}>
                            <i className="times-custom"/>
                        </div>
                    </div>
                    <div className="mini-cart-body">
                        <div className="mini-cart-item-list">

                            {
                                cartData.length > 0 ?
                                    cartData.map((item, index) => {
                                        return <div className="single-c-item" key={index}>
                                            {
                                                (adjustQtyLoader && currentItem.product_id === item.product_id) ?
                                                    <div className="qty-loader-main">
                                                        <ButtonLoader/>
                                                    </div> :
                                                    <div className="cart-quantity">
                                                        <div className="input-group">
                                                            <span className="input-group-text up" onClick={() => {
                                                                handleQuantityChange(item, 'increment')
                                                            }}/>
                                                            <div
                                                                className="form-control text-center">{item.ordered_quantity}</div>
                                                            <span className="input-group-text down" onClick={() => {
                                                                handleQuantityChange(item, 'decrement')
                                                            }}/>
                                                        </div>
                                                    </div>
                                            }
                                            <img src={item.product_img}/>
                                            <div className="content">
                                                <strong>{item.product_name}</strong>
                                                <div className="qtty">
                                                    <span>{item.ordered_price} x {item.ordered_quantity} = {parseFloat(item.ordered_price * item.ordered_quantity).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div className="cart-item-remove">
                                                {
                                                    (removeItemLoader && currentItem.product_id === item.product_id) ?
                                                        <ButtonLoader/> :
                                                        <i className={removeItemLoader ? 'times-custom disabled' : 'times-custom'}
                                                           onClick={() => {
                                                               removeFromCart(item)
                                                           }}/>
                                                }
                                            </div>
                                        </div>
                                    }) : <p className="text-center text-primary p-4">No items in the cart</p>
                            }

                        </div>
                    </div>
                    <div className="mini-cart-footer">
                        <div className="checkout-button" disabled={cartData.length <= 0}>
                            <Link to={'#'} onClick={redirectToCheckout}>Checkout</Link>
                        </div>
                        <div className="subtotal-button">
                            <strong>BDT {totalPrice}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCart;
