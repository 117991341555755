import React from "react"
import "./static/404.scss"
import ErrorImage from "./static/images/404.png"
import Commonbutton from "../../components/button/Button";
import path from "../../routes/path";
import {Link, useHistory} from "react-router-dom";

const Error404 = () => {
    const history = useHistory();
    const redirectToHome = () => {
        history.push({
            pathname: `${path.home}`,
        });
    }
    return (
        <div className="error-404-page">
            <div className="error-404-page__wrapper">
                <div className="main-content d-flex justify-content-center">
                    <div className="text-center main-content__wrapper">
                        <img width={135} src={ErrorImage} alt="404 image"/>
                        <h1 className="page-title">Oh Sorry!</h1>
                        <h6>The page you were looking for doesn’t exist</h6>
                        <p>You might have typed in the wrong address or the page has been moved. In the
                            meantime,
                            try again or Return to the home page</p>
                        <div className="action-block">
                            <Commonbutton
                                type="button"
                                onclickCallback={redirectToHome}
                                // disabled={}
                                inputClassName="form-submit"
                                // isLoading={isLoading}
                                btnText="Home"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error404
