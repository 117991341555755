export const membershipValues = {
    NON_MEMBER: "non_member",
    LIFETIME_MEMBER: "lifetime_member",
    GENERAL_MEMBER: "general_member",
    TEACHER: "teacher",
    ASSOCIATE_OFFICE_STAFF: "asc_ofc_staff",
    STUDENT: "student",
    COLLEGIATE_LAB: "collegiate_lt",
    FAMILY: "family",
    VISITOR: "visitor",
}

export const membershipOptions = [
    { label: "Life Member", value: membershipValues.LIFETIME_MEMBER },
    { label: "General Member", value: membershipValues.GENERAL_MEMBER },
    { label: "Non Member", value: membershipValues.NON_MEMBER },
    // {label: "Teacher", value: membershipValues.TEACHER},
    // {label: "Associate (office staff)", value: membershipValues.ASSOCIATE_OFFICE_STAFF},
    // {label: "Student", value: membershipValues.STUDENT},
    // {label: "Collegiate Laboratorian", value: membershipValues.COLLEGIATE_LAB},
    // {label: "Family", value: membershipValues.FAMILY},
    // {label: "Visitor", value: membershipValues.VISITOR},
]

export const membershipOptionsPre = [
    { label: "Life Member", value: membershipValues.LIFETIME_MEMBER },
    { label: "General Member", value: membershipValues.GENERAL_MEMBER }
]

export const relationshipStatus = [
    { label: "Single", value: 'single' },
    { label: "Married", value: 'married' },
    { label: "Divorced", value: 'divorced' },
]

export const degree = [
    { label: "SSC", value: 'ssc' },
    { label: "HSC", value: 'hsc' },
    { label: "BSC", value: 'bsc' },
    { label: "BA", value: 'ba' },
    { label: "MCS", value: 'mcs' },
    { label: "MA", value: 'ma' },
    { label: "PhD", value: 'phd' },
]

export const bloodGroup = ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"];

export const wordList = () => {
    let f = []
    for (let i = 1; i <= 100; i++) {
        f.push({
            label: i,
            value: i
        })
    }

    return f
}

