import {createAsyncThunk, createSlice,} from "@reduxjs/toolkit";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";

export const fetchSignUp1 = createAsyncThunk('auth/preRegistrationSlice/add',
    (data) =>
        AxiosServices
            .post(ApiUrlServices.SIGN_UP1, data, false)
            .then(response => data = {
              "formData": data,
              "data": response.data
            })
            .catch(error => error),
);

const preRegistrationInitialState = {
  values: {},
  errors: {},
  status: null
};

const preRegistrationSlice = createSlice({
  name: 'preRegistrationSlice',
  initialState: preRegistrationInitialState,
  reducers: {
    addPreRegisterData(state, action) {
      console.log('payload', action.payload);
      state.values = {...state.values, ...action.payload};
      state.status = true;
    },
    clearPreRegisterData(state, action) {
      console.log('payload', action.payload);
      state.values = {...preRegistrationInitialState};
      state.status = true;
    }

  },
  extraReducers: {

    [fetchSignUp1.pending.type]: (state, action) => {
      state.values = {...action.payload};
      state.status = "loading";
    },
    [fetchSignUp1.fulfilled.type]: (state, action) => {
      state.values = {...action.payload};
      state.status = "idle";
    },
    [fetchSignUp1.rejected.type]: (state, action) => {
      state.values = {...action.payload};
      state.errors = action.error;
      state.status = "idle";
    }

  },

});

export const {addPreRegisterData, clearPreRegisterData} = preRegistrationSlice.actions;

export default preRegistrationSlice.reducer;
