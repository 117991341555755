import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const axios = require('axios');

export const fetchRegistration = createAsyncThunk('auth/fetchRegistrationLoading',
    (data) =>
        AxiosServices
            .post(ApiUrlServices.REGISTER, data, false)
            .then(response => data = {
                "formData": data,
                "data": response.data
            })
            .catch(error => error),
);

const registrationInitialState = {
    registrationList: {
        status: 'idle',
        data: {},
        error: {}
    }
};

const registrationSlice = createSlice({
    name: 'registrationData',
    initialState: registrationInitialState,
    reducers: {},
    extraReducers: {
        [fetchRegistration.pending.type]: (state, action) => {
            state.registrationList = {
                status: 'loading',
                data: action.payload,
                error: {}
            };
        },
        [fetchRegistration.fulfilled.type]: (state, action) => {
            state.registrationList = {
                status: 'idle',
                data: action.payload,
                error: {}
            };
        },
        [fetchRegistration.rejected.type]: (state, action) => {
            state.registrationList = {
                status: 'idle',
                data: action.payload,
                error: action.payload,
            };
        },
    }
});

export default registrationSlice.reducer;
