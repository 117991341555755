import React, {lazy, Suspense} from "react";
import CustomPageLoader from "../../../components/customPageLoader";
import {Switch} from "react-router-dom";
import requireLogin from "../routerControl";
import {GuardProvider, GuardedRoute} from 'react-router-guards';
import Redirect from "react-router-dom/es/Redirect";

import {Header} from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import path from "../../../routes/path";

import './static/styles.scss'

const HomePage = lazy(() => import('../../../app/home/HomePage'));
const Registration = lazy(() => import('../../../app/registration/Registration'));
const Profile = lazy(() => import('../../../app/profile/Profile'));
const Search = lazy(() => import('../../../app/search/Search'));
const AuthIntermediary = lazy(() => import('../../../app/authIntermediaryPage/authIntermediary'));
const IntroducerApproval = lazy(() => import('../../../app/introducer-approval/IntroducerApproval'));
const IntroducerDisApproval = lazy(() => import('../../../app/introducer-approval/IntroducerDisApproval'));
const Blog = lazy(() => import('../../../app/blog/Blog'));
const Gallery = lazy(() => import('../../../app/gallery/Gallery'));
const ContactUs = lazy(() => import('../../../app/contact/ContactUs'));
const ProfileSearch = lazy(() => import('../../../app/profile-search/ProfileSearch'));
const TermsAndConditions = lazy(() => import('../../../app/terms-and-conditions/TermsAndConditions'));
const AboutUs = lazy(() => import('../../../app/aboutus/AboutUs'));
const PrivacyPolicy = lazy(() => import('../../../app/privacy-policy/PrivacyPolicy'));
const AboutSchool = lazy(() => import('../../../app/about-school/AboutSchool'));
const ForumDetails = lazy(() => import('../../../app/forum-details/ForumDetails'));
const EventProductCategory = lazy(() => import('./../../../app/event-product/EventProductCategory'));
const EventProductCategoryDetails = lazy(() => import('./../../../app/event-product/EventProductCategoryDetails'));
const EventProductDetails = lazy(()=> import('./../../../app/event-product/EventProductDetails'));
const Checkout = lazy(()=> import('./../../../app/checkout/Checkout'));
const PaymentSuccessModal = lazy(()=> import('./../../../app/checkout/PaymentSuccessModal'));
const MyOrderList = lazy(()=> import('./../../../app/checkout/MyOrderList'));


const routes = [
    {
        accessRequired: "both",
        path: "/",
        exact: true,
        component: HomePage,
    },

    {
        accessRequired: "both",
        path: path.home,
        exact: true,
        component: HomePage,
    },

    {
        accessRequired: "yes", // both?
        path: path.complete_registration,
        exact: false,
        component: Registration,
    },

    {
        accessRequired: "yes", // TODO: please change to 'yes' before deploy
        path: path.introducer_approval,
        exact: false,
        component: IntroducerApproval,
    },
    {
        accessRequired: "both",
        path: path.introducer_disapproval,
        exact: false,
        component: IntroducerDisApproval,
    },
    {
        accessRequired: "yes", //TODO: please change to 'yes' after ui test
        path: path.profile,
        exact: true,
        component: Profile,
    },
    {
        accessRequired: "yes", //TODO: please change to 'yes' after ui test
        path: `${path.profile}/:user_id`,
        exact: true,
        component: Profile,
    },
    {
        accessRequired: "both",
        path: path.forum_details,
        exact: true,
        component: ForumDetails,
    },
    {
        accessRequired: "both",
        path: path.blog,
        exact: true,
        component: Blog,
    },
    {
        accessRequired: "both",
        path: path.gallery,
        exact: true,
        component: Gallery,
    },

    {
        accessRequired: "both", // change later
        path: path.search,
        exact: true,
        component: Search,
    },
    {
        accessRequired: "yes",
        path: path.auth_intermediary,
        exact: true,
        component: AuthIntermediary,
    },

    {
        accessRequired: "yes",
        path: path.profile_search,
        exact: true,
        component: ProfileSearch,
    },
    {
        accessRequired: "yes",
        exact: true,
        path: path.event,
        component: EventProductCategory,
    },
    {
        accessRequired: "yes",
        exact: true,
        path: path.product,
        component: EventProductCategory,
    },
    {
        accessRequired: "yes",
        exact: true,
        path: path.category_product,
        component: EventProductCategoryDetails,
    },
    {
        accessRequired: 'yes',
        exact: true,
        path: path.product_details,
        component: EventProductDetails,
    },
    {
        accessRequired: "yes",
        exact: true,
        path: path.event_details,
        component: EventProductCategoryDetails,
    },
    {
        accessRequired: "both",
        path: path.contact_us,
        component: ContactUs,
    },
    {
        accessRequired: "both",
        path: path.about_us,
        component: AboutUs,
    },
    {
        accessRequired: "both",
        path: path.terms_and_conditions,
        component: TermsAndConditions,
    },
    {
        accessRequired: "both",
        path: path.privacy_policy,
        component: PrivacyPolicy,
    },
    {
        accessRequired: "both",
        path: path.about_school,
        component: AboutSchool,
    },
    {
        accessRequired: "yes",
        path: path.product_payment_success,
        component: PaymentSuccessModal,
    },
    {
        accessRequired: "yes",
        path: path.my_order_list,
        component: MyOrderList,
    },
    {
        accessRequired: "yes",
        path: path.checkout,
        component: Checkout,
    },
];

export default ({props}) => {
    // console.log("render default layout");

    return (
        <>
            <Header/>
            <div className="default-layout-main">
                <Suspense fallback={<h4 className="height-450">Loading . . .</h4>} /*fallback={<CustomPageLoader pageLoader={true}/>}*/>
                    <GuardProvider guards={[requireLogin]} /*loading={Loading}*/ error={<h1>Not allowed</h1>}>
                        <Switch>
                            {routes.map((route, i) =>
                                <GuardedRoute
                                    key={i}
                                    path={route.path}
                                    exact={route.exact}
                                    name={`${route.path}`}
                                    component={route.component}
                                    meta={{auth: route.accessRequired}}
                                />
                            )}
                            <Redirect to={path.error_404}/>
                        </Switch>
                    </GuardProvider>
                </Suspense>
            </div>
            <Footer/>
        </>
    );
};
