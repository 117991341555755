import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "userReducer",
    initialState: {
        userDetails: {},
    },
    reducers: {
        setUserDetailsValue(state, action) {
            state.userDetails = action.payload;
        }
    }
});

export const userAction = userSlice.actions;

export default userSlice;
