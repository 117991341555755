import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Link, Switch, useHistory } from "react-router-dom"
import AxiosService from "../../../networks/AxiosService";
import ApiUrlServices from "../../../networks/ApiUrlServices";
import { aws } from "../../../config";
import AxiosServices from "../../../networks/AxiosService";
import ElementLoader from "../../../components/elementLoader/ElementLoader";

import "./../../../app/forum/static/forum.scss";
import frofile from './../../../app/forum/static/image/profile.png'
import path from "../../../routes/path";
import CustomPageLoader from "../../../components/customPageLoader";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import requireLogin from "../../../containers/layouts/routerControl";
import Redirect from "react-router-dom/es/Redirect";
import { Header } from "../../../components/header";
import Footer from "../../../components/footer/Footer";
import NameAndBatch from "../../../components/NameAndBatch/NameAndBatch";
import {useSelector} from "react-redux";

const ForumMiddle = lazy(() => import('./../../../app/forum/ForumMiddle'));
const ForumSingle = lazy(() => import('../../../app/forum/ForumPostSingle'));

// import './static/styles.scss'

const Forum = (props) => {
    let mapData = useSelector(state => state.paginationReducer.dynamoMap);
    // console.log(props)
    const [forumPosts, setForumPosts] = useState([]);
    const [payload, setPaylaod] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [totalPage, setTotalPage] = useState(1);
    const [pageLoader, setPageLoader] = useState(false);


    // Posts from different category
    const [announcementPost, setAnnouncementPost] = useState([]);
    const [scholarshipPost, setScholarshipPost] = useState([]);
    const [jobOfferPost, setJobOfferPost] = useState([]);
    const [upcomingEventsPost, setUpcomingEventsPost] = useState([]);
    const [latestNewsPost, setLatestNewsPost] = useState([]);
    const [galleryPost, setGalleryPost] = useState([]);

    // Loader for different category
    const [annLoader, setAnnLoader] = useState(false);
    const [jobLoader, setJobLoader] = useState(false);
    const [scholLoader, setScholLoader] = useState(false);
    const [upcomingEventsLoader, setUpcomingEventsLoader] = useState(false);
    const [latestNewsLoader, setLatestNewsLoader] = useState(false);
    const [galleryLoader, setGalleryLoader] = useState(false);

    const [oldCategoryId, setOldCategoryId] = useState("")
    const [editedCategory, setEditedCategory] = useState(""); // Category which was selected

    const [selectedTab, setSelectedTab] = useState(""); // Which Tab was selected, [My Posts, Tagged me]
    const [init, setInit] = useState(true); // Is the state initial

    const history = useHistory();


    const [defaultOp, setDefaultOp] = useState([
        { "cat_id": "", cat_name: "All Category", status: "" },
        { "cat_id": 1, "cat_name": "Announcements", "status": "active" },
        { "cat_id": 2, "cat_name": "Job offer", "status": "active" },
        { "cat_id": 3, "cat_name": "Scholarship", "status": "active" },
        { "cat_id": 4, "cat_name": "Gallery", "status": "active" },
        { "cat_id": 5, "cat_name": "Upcoming events", "status": "active" },
        { "cat_id": 6, "cat_name": "Latest News", "status": "active" }
    ]);

    const categoryMap = {
        "1": 1,
        "2": 2,
        "3": 3,
        '4': 4,
        '5': 5,
        '6': 6
    };

    const [defOp, setDefOp] = useState([]);


    const [selectedCat, setSelectedCat] = useState(defaultOp[0]);

    const defOpSett = ["", setAnnouncementPost, setJobOfferPost, setScholarshipPost, setUpcomingEventsPost, setLatestNewsPost, setGalleryPost]; // Assign the setters on a array
    const defOpLoad = ["", setAnnLoader, setJobLoader, setScholLoader, setUpcomingEventsLoader, setLatestNewsLoader, setGalleryLoader]; // Assign loader setters on a array
    const initPayload = {
        search_params: {
            match_params: {},
            filter_params: {
                category_id: {
                    op: "=",
                    value: 1
                }
            }
        },
        page: 1,
        limit: 10,
        fields: [
            "categroy_name",
            "posted_user_name",
            "content_html",
            "commented_user_name",
            "posted_by_id",
            "content",
            "category_id",
            "post_id",
            "mention_users",
            "hash_tag",
            "urls",
            "timestamp",
            "date",
            "media_files",
            "status"
        ]
    }

    useEffect(() => {
        if (userProfile.name) {
            if (userProfile.isAdminApprovalPending !== false || userProfile.isIntroducerApprovalPending !== false) {
                history.push(path.complete_registration);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const id = categoryMap[editedCategory];
        if (editedCategory && id) {
            const defPayload = {
                ...initPayload,
                search_params: {
                    match_params: {},
                    filter_params: {
                        category_id: {
                            op: "=",
                            value: defaultOp[id].cat_id
                        }
                    }
                }
            }

            fetchForumPosts(1, 5, defOpSett[id], defOpLoad[id], true, defPayload);
            setEditedCategory("");
        }
    }, [editedCategory])
    useEffect(()=>{
        const id = categoryMap[oldCategoryId];
        if (oldCategoryId && id) {
            const defPayload = {
                ...initPayload,
                search_params: {
                    match_params: {},
                    filter_params: {
                        category_id: {
                            op: "=",
                            value: defaultOp[id].cat_id
                        }
                    }
                }
            }

            fetchForumPosts(1, 5, defOpSett[id], defOpLoad[id], true, defPayload);
            setEditedCategory("");
            setOldCategoryId("");
        }
    },[oldCategoryId])

    useEffect(() => {
        getInitCate();

        const payload = { ...initPayload }
        if (history.location?.state?.selectedCat) {
            setSelectedCat(history.location.state.selectedCat);
            payload.search_params = {
                match_params: {},
                filter_params: {
                    category_id: {
                        op: "=",
                        value: history.location.state.selectedCat.cat_id
                    }
                }
            }
        }
        setPaylaod(payload);

        // Fetch forum posts depend on different category
        defaultOp.map((op, id) => {
            if (id > 6 || id === 0) {
                return;
            }
            const defPayload = {
                ...initPayload,
                search_params: {
                    match_params: {},
                    filter_params: {
                        category_id: {
                            op: "=",
                            value: defaultOp[id].cat_id
                        }
                    }
                }
            }

            fetchForumPosts(1, 5, defOpSett[id], defOpLoad[id], true, defPayload);

        })

    }, []);

    useEffect(() => {
        if (typeof history.location.state === typeof "he") {
            setSelectedTab(history.location.state);
        }
        else if (history.location.state?.createdCat) {
            let index;
            setSelectedTab("")
            defaultOp.map((op, id) => {
                if (op.cat_id === history.location.state?.createdCat) {
                    index = id
                }
            })

            if (index) {
                const defPayload = {
                    ...initPayload,
                    search_params: {
                        match_params: {},
                        filter_params: {
                            category_id: {
                                op: "=",
                                value: defaultOp[index].cat_id
                            }
                        }
                    }
                }

                // fetchForumPosts(1, 5, defOpSett[index], defOpLoad[index], true, defPayload);
            }

            // alert(JSON.stringify(index))
        }
        else {
            setSelectedTab("")
        }
    }, [history.location.state]);

    useEffect(() => {
        if (!payload.search_params) {
            return;
        }
        fetchForumPosts(1, 10, setForumPosts, setPageLoader, payload);
    }, [payload]);

    const fetchForumPosts = (page = 1, limit = 10, postSetter = setForumPosts, loader = setPageLoader, isSidebar = false, payloadS) => {
        loader(true);
        const newPayload = payloadS ? { ...payloadS } : { ...payload }
        newPayload.limit = limit;
        newPayload.page = page;
        let catId = newPayload.search_params.filter_params.category_id.value
        AxiosService.post(ApiUrlServices.GET_FORUM_POST(catId), newPayload)
            .then(res => {
                // console.log("ANNOUNCEMENT DATA:::::::::::::::::::::::::: ",res.data);
                if (!isSidebar) {
                    setTotalPage(Math.ceil(Number(res.data.data.count.value) / 10));
                    if (init) {
                        setInit(false);
                    }
                }
                postSetter(res.data.data.posts)
            })
            .catch(err => {
                console.log(err)
            })
            .finally((...rest) => {
                loader(false);
                // console.log(rest)
            })

    }

    const getInitCate = () => {
        let payload = {
            status: "active"
        }
        // console.log(payload)
        AxiosServices.post(ApiUrlServices.GET_CATEGORIES(500, 0), payload)
            .then(response => {
                setDefOp(response.data.data.category)
            })
            .catch(err => {
                return err;
            })
    }

    const onSelectMyPosts = () => {
        history.push({
            pathname: path.forum,
            state: "myPost",
            search: '?page=1'
        })
    }

    const onSelectTaggedMe = () => {
        history.push({
            pathname: path.forum,
            state: "taggedMe",
            search: '?page=1'
        })
    }

    const onSelectTaggedMeComment = () => {
        history.push({
            pathname: path.forum,
            state: "taggedComment",
            search: '?page=1'
        })
    }

    const truncateText = (text) => {

        if (text.length >= 60) {
            let words = text.slice(0, 60).split(' ')
            // console.log(words)
            if (words.length===1){
                text = words[0].slice(0,15) + "..."
            }else{
                words[words.length - 1] = "..."
                text = words.join(' ')
            }
        }
        return text
        // return text.length >= 26 ? `${text.slice(0, 26)}...` : text;
    }

    const routes = [
        {
            accessRequired: "yes",
            path: path.forum,
            exact: true,
            component: ForumMiddle,
        },
        {
            accessRequired: "yes",
            path: `${path.forum}/:post_id`,
            exact: true,
            component: ForumSingle,
        },
    ]

    let fullName = (userProfile.firstName || userProfile.middleName || userProfile.lastName) ? (userProfile.firstName + " " + userProfile.middleName + " " + userProfile.lastName) : userProfile.name

    return (
        <>
            <Header setUserProfile={setUserProfile} />

            <div className="default-layout-main">
                <div className="section-padding-2 section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 order-lg-first order-last">
                                <div className="forum_single_widgets">
                                    <div className="auth">
                                        <div className="left">
                                            <Link to={path.profile}>
                                                <img src={!!!userProfile.name ? '' : userProfile?.profile_picture ? `${aws.s3_base_url}${userProfile?.profile_picture}` : frofile} />
                                            </Link>
                                        </div>
                                        <div className="right">
                                            <Link to={path.profile}>
                                                <p>
                                                    {fullName}
                                                </p>
                                            </Link>
                                            {/*<span>CEO</span>*/}
                                        </div>
                                    </div>
                                    <div className="list">
                                        <ul>
                                            <li><p
                                                className={`${selectedTab === "myPost" ? "selected-tab" : "normal-tab"}`}
                                                onClick={onSelectMyPosts}>My posts</p>
                                            </li>
                                            <li><p
                                                className={`${selectedTab === "taggedMe" ? "selected-tab" : "normal-tab"}`}
                                                onClick={onSelectTaggedMe}>Tagged posts</p>
                                            </li>
                                            <li><p
                                                style={{ display: "none" }}
                                                className={`${selectedTab === "taggedComment" ? "selected-tab" : "normal-tab"}`}
                                                onClick={onSelectTaggedMeComment}>Tagged in Comments</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="forum_single_widgets-1">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[1]
                                        }
                                    }}><h2 className="title">{defaultOp[1].cat_name}</h2></Link>

                                    <div className="list">
                                        {
                                            !announcementPost.length && !annLoader ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        announcementPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        {
                                            announcementPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[1]
                                                        }
                                                    }}>See All</Link>
                                                </div>
                                                : null
                                        }
                                        <ElementLoader
                                            loader={annLoader}
                                        />
                                    </div>
                                </div>

                                <div className="forum_single_widgets-1 ">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[4]
                                        }
                                    }}> <h2 className="title">{defaultOp[4].cat_name}</h2>
                                    </Link>

                                    <div className="list">
                                        {
                                            !upcomingEventsPost.length && !upcomingEventsLoader ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        upcomingEventsPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        {
                                            upcomingEventsPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[4]
                                                        }
                                                    }}>See All</Link>
                                                </div>
                                                : null
                                        }
                                        <ElementLoader
                                            loader={upcomingEventsLoader}
                                        />
                                    </div>
                                </div>
                                <div className="forum_single_widgets-1 ">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[6]
                                        }
                                    }}
                                    ><h2 className="title ">{defaultOp[6].cat_name}</h2>
                                    </Link>

                                    <div className="list">
                                        {
                                            !galleryPost.length && !galleryLoader ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        galleryPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        <ElementLoader
                                            loader={galleryLoader}
                                        />
                                        {
                                            galleryPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[6]
                                                        }
                                                    }}
                                                    >See All</Link>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <Suspense fallback={<h4 className="height-450">Loading . . .</h4>} /*fallback={<CustomPageLoader pageLoader={true}/>}*/>
                                    <GuardProvider guards={[requireLogin]} /*loading={Loading}*/
                                        error={<h1>Not allowed</h1>}>
                                        <Switch>
                                            {routes.map((route, i) =>
                                                <GuardedRoute
                                                    key={i}
                                                    path={route.path}
                                                    exact={true}
                                                    name={`${route.path}`}
                                                    // component={route.component}
                                                    render={
                                                        props => (
                                                            <route.component
                                                                {...props}
                                                                setEditedCategory={setEditedCategory} // category will sent to every route component.
                                                                userProfile={userProfile} // Sent user Profile on every component of this routing table.
                                                                setOldCategoryId ={setOldCategoryId}
                                                            />
                                                        )
                                                    }
                                                    meta={{ auth: route.accessRequired }}
                                                />
                                            )}
                                            <Redirect to={path.error_404} />
                                        </Switch>
                                    </GuardProvider>
                                </Suspense>
                            </div>

                            <div className="col-lg-3 col-sm-6 order-last">
                                <div className="forum_single_widgets-1">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[2]
                                        }
                                    }}><h2 className="title">{defaultOp[2].cat_name}</h2>
                                    </Link>


                                    <div className="list">
                                        {
                                            !jobLoader && !jobOfferPost.length ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        jobOfferPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        <ElementLoader
                                            loader={jobLoader}
                                        />
                                        {
                                            jobOfferPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[2]
                                                        }
                                                    }}>See All</Link>
                                                </div> : null
                                        }
                                    </div>
                                </div>

                                <div className="forum_single_widgets-1">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[3]
                                        }
                                    }}
                                    > <h2 className="title ">{defaultOp[3].cat_name}</h2>
                                    </Link>

                                    <div className="list">
                                        {
                                            !scholarshipPost.length && !scholLoader ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        scholarshipPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        <ElementLoader
                                            loader={scholLoader}
                                        />
                                        {
                                            scholarshipPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[3]
                                                        }
                                                    }}
                                                    >See All</Link>
                                                </div> : null
                                        }
                                    </div>
                                </div>

                                <div className="forum_single_widgets-1 ">
                                    <Link to={{
                                        pathname: "/forum",
                                        state: {
                                            selectedCat: defaultOp[5]
                                        }
                                    }}
                                    >
                                        <h2 className="title ">{defaultOp[5].cat_name}</h2>
                                    </Link>

                                    <div className="list">
                                        {
                                            !latestNewsPost.length && !latestNewsLoader ?
                                                <>No Posts Found</> :
                                                <ul>
                                                    {
                                                        latestNewsPost.slice(0, 5).map(ann => {
                                                            return <li><Link
                                                                to={`/forum/${ann.post_id}`}>{truncateText(ann.content)}</Link>{ }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                        }
                                        <ElementLoader
                                            loader={latestNewsLoader}
                                        />
                                        {
                                            latestNewsPost.length >= 5 ?
                                                <div className="se-all">
                                                    <Link to={{
                                                        pathname: "/forum",
                                                        state: {
                                                            selectedCat: defaultOp[5]
                                                        }
                                                    }}
                                                    >See All</Link>
                                                </div> : null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );
}
export default Forum;
