import path from "../../routes/path";

const qs = require('query-string');

const requireLogin = (to, from, next) => {
	const lastPath = to.location.pathname;
	const parsed = qs.parse(to.location.search);

	console.log(to, from);
	const getIsLoggedIn = () => {
		const userData = JSON.parse(localStorage.getItem('userData'));
		return !!userData?.sessionToken;
	}

	if(to.meta.auth === "both") {
		next();
	}
	else if (to.meta.auth === "yes") {
		if (getIsLoggedIn()) {
			next();
		}
		else {
			let myDynamicPath = "";
			if(Object.entries(parsed).length) {
				for (const [key, value] of Object.entries(parsed)) {
					if(!key || !value) {
						continue;
					}
					myDynamicPath = myDynamicPath + `&${key}=${value}`;
				}
			}
			next.redirect(`${path.login}?redirect_to=${lastPath}${myDynamicPath.length ? myDynamicPath : ""}`);
		}
	}
	else {
		if (getIsLoggedIn()) {
			next.redirect(path.home);
		}
		else {
			next();
		}
	}
};

export default requireLogin;
