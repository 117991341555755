import {createSlice} from "@reduxjs/toolkit";

const paginationSlice = createSlice({
    name: "paginationReducer",
    initialState: {
        dynamoMap : {},
        selectedCategory : { "cat_id": "", cat_name: "All Category", status: "" },
    },
    reducers: {
        setDynamoMap(state, action) {
            state.dynamoMap = {...state.dynamoMap, ...action.payload};
        },
        setSelectedCategory(state, action) {
            state.selectedCategory = {...state.selectedCategory, ...action.payload};
        }
    }
});

export const paginationAction = paginationSlice.actions;

export default paginationSlice;