import React from 'react';
import Style from './CustomPageLoader.module.scss';

export default function CustomPageLoader(props) {

    return (
        props.pageLoader ? <>
                <div className={`${Style.overlayed}`}/>
                <div className={`${Style.loader} ${props.className}`}/>
            </>
            : ""
    );
}
