import React from 'react'

export default function NameAndBatch({ name, batch }) {
    return (
        <div>{name}{" "}
        <br/>{batch &&
            <span className='nameAndBatch'>Lab'{batch?.slice(2)}</span>}
        </div>
    )
}
