import {
    configureStore,
    combineReducers
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import registrationSlice from "./auth/registration";
import loginSlice from "./auth/login";
import logger from "redux-logger";
import {persistReducer} from "redux-persist";
import preRegistrationSlice from "./auth/pre_registration";
import cartSlice from "./cartSlice";
import userSlice from "./userSlice";
import productSlice from "./productSlice";
import paginationSlice from "./paginationSlice";

const reducers = combineReducers({
    preRegistrationSlice,
    registrationSlice,
    loginSlice,
    cartReducer: cartSlice.reducer,
    userReducer: userSlice.reducer,
    productReducer: productSlice.reducer,
    paginationReducer : paginationSlice.reducer
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(logger),
    devTools: process.env.NODE_ENV !== "production",
});
export default store;
