const path = {
  home: "/",
  social_redirect_url: '/auth/social-login-int',
  auth: "/auth",
  login: "/auth/login",
  logout: "/auth/logout",

  forgot_password: '/auth/forget-password',
  forgot_password_phone_confirm: '/auth/forget-password-phone-confirmation',
  forgot_password_confirm: '/auth/forget-password-confirm',
  reset_password: '/auth/reset-password',
  reset_password_phone: '/auth/reset-password-phone',

  auth_intermediary: "/auth-intermediary",
  register: "/auth/registration",

  complete_registration: "/auth/complete-registration",

  registration_confirmation: "/auth/registration-confirmation",
  registration_phone_confirmation: "/auth/registration-phone-confirmation",
  confirm_signup: "/auth/confirm-signup",
  test: "/test",
  introducer_approval: "/introducer-approval",
  introducer_disapproval: "/introducer-disapproval",
  profile: "/profile",
  search: "/search",
  blog: "/blog",
  gallery: "/gallery",
  job: "/job",
  forum:"/forum",
  forum_single:"/forum",
  forum_details:"/forum-details",
  contact_us: "/contact-us",
  profile_search:"/profile-search",
  terms_and_conditions: "/terms-and-conditions",
  about_us: "/about-us",
  event: "/event",
  product: "/product",
  category_product: "/category-product",
  product_details: "/product-details",
  event_details: "/event/event-details",
  checkout: "/checkout",
  my_order_list: "/my-order-list",
  product_payment_success: "/product-payment-successful",
  privacy_policy:"/privacy-policy",
  about_school:"/about-school",
  error_404:"/error-404"

};

export default path;
