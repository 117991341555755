import React from 'react';
import {Link} from 'react-router-dom';
import Logo from './static/images/OLsA_Logo.png';
import path from '../../routes/path';
import './Footer.scss';

const Footer = () => {
	return (
		<>
			<footer id="footer-area">
				<div className="footer-widget section-padding">
					<div className="container footer-div">
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="single-widget-wrap">
									<h4 className="widget-title">Get In Touch</h4>
									<div className="widgei-body">
										<Link className="gmail" to={void 0}>
											<i className="fa fa-envelope-o" aria-hidden="true" /> info@laboratorians.net
										</Link>
										<div className="location">
											<i className="fa fa-map-marker" aria-hidden="true" />
											<p>Government Laboratory High School Premise Dhanmondi, Dhaka-1205, Bangladesh</p>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-4 col-md-6">
								<div className="single-widget-wrap">
									<h4 className="widget-title">Social Link</h4>
									<div className="widgei-body">
										<div className="footer-social-icons pt-3">
											<a
												href="https://www.youtube.com/user/TheLaboratorians"
												target="_blank"
												rel="noreferrer">
												<i style={{fontSize: '22px'}} className="fa fa-youtube-play" aria-hidden="true" />
											</a>
											<a href="https://www.facebook.com/thelaboratorians" target="_blank" rel="noreferrer">
												<i style={{fontSize: '22px'}} className="fa fa-facebook" aria-hidden="true" />
											</a>
											<a href="https://www.linkedin.com/company/thelaboratorians/" target="_blank" rel="noreferrer">
												<i style={{fontSize: '22px'}} className="fa fa-linkedin" aria-hidden="true" />
											</a>
											<a href="https://twitter.com/olsa1961" target="_blank" rel="noreferrer">
												<i style={{fontSize: '22px'}} className="fa fa-twitter" aria-hidden="true" />
											</a>
											<a href="https://www.instagram.com/laboratorians/" target="_blank" rel="noreferrer">
												<i style={{fontSize: '22px'}} className="fa fa-instagram" aria-hidden="true" />
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-4 col-md-6">
								<div className="single-widget-wrap">
									<h4 className="widget-title">Useful Links</h4>
									<div className="widgei-body">
										<ul className="double-list footer-list clearfix">
											<li style={{float: 'none', width: 'auto'}}>
												<a href={path.terms_and_conditions}>Terms and Conditions</a>
											</li>
											<li style={{float: 'none', width: 'auto'}}>
												<a href={path.privacy_policy}>Privacy Policy</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<div className="footer-bottom-text">
									<p>&copy; {new Date().getFullYear()} OLsA, All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<Link to={void 0} className="scroll-top">
				<i className="fa fa-angle-up" />
			</Link>
		</>
	);
};

export default Footer;
