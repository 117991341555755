import React, {useEffect, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import logo1 from "./static/images/logo1.png";
import path from "../../routes/path";
import $ from "jquery";
import './static/header.css'
import useAuth from "../../hooks/auth/useAuth";
import {useDispatch, useSelector} from "react-redux";
import ModalBootstrap from "../modalBootstrap/ModalBootstrap";
import {userStatusValue} from "../../app/registration/utils";
import CustomPageLoader from "../customPageLoader";
import MyCart from "../MyCart/MyCart";
import {paginationAction} from "../../store/paginationSlice";
import {membershipValues} from "../../helper/utils";

export const Header = (props) => {
    const preRegistrationSlice = useSelector(
        (state) => state.preRegistrationSlice
    );

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalData, setModalData] = useState("");
    const dispatch = useDispatch()
    useEffect(() => {
        $(document).ready(function () {
            $(window).on('scroll', function () {
                var scroll = $(window).scrollTop();
                if (scroll < 60) {
                    $('.header-bottom-area').removeClass('sticky');
                } else {
                    $('.header-bottom-area').addClass('sticky');
                }
            });
        });
    }, []);

    const {pageLoader, logoutLoader, loggedIn, logout, isProfile, isAuthLoading, responseData} = useAuth();
    const location = useLocation();
    const currentPath = location.pathname;

    const [addClass, setAddClass] = useState(false)

    const hasUnsavedActivityInCompleteRegPage = () => {
        const userStatus = preRegistrationSlice.values.userStatus
        const paymentStatus = preRegistrationSlice.values.isPaymentReceived
        return userStatus === userStatusValue.PENDING && paymentStatus;
    }

    const hasUnfinishedTask = () => {
        return hasUnsavedActivityInCompleteRegPage();
    }

    const showWarning = (msg) => {
        setModalTitle("Warning")
        setModalData(msg)
        setShowWarningModal(true)
    }

    const handleLogout = (e) => {
        e.preventDefault();
        if (hasUnfinishedTask()) {
            showWarning("Please save your data first. You paid for it. You cannot log out without saving data.")
        } else {
            logout();
        }
    }

    useEffect(() => {
        if (responseData?.name) {
            if (props.setUserProfile) {
                props.setUserProfile(responseData);
            }
        }
    }, [responseData])


    const navActiveRemove = () => {
        dispatch(paginationAction.setSelectedCategory({"cat_id": "", cat_name: "All Category", status: ""}))
        // e.preventDefault()
        $(".mobile-menu-sidebar, .off-canvas, .mobile-nav-icon").removeClass("active");

    }


    return (
        <>
            <header id="header-area">
                <CustomPageLoader pageLoader={logoutLoader}/>
                <div className="preheader-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-7 col-7 align-self-center">
                                <div className="preheader-left">
                                    <a href="mailto:info@laboratorians.net" style={{fontFamily: "'Roboto'"}}>
                                        <i className="fa fa-envelope-o" aria-hidden="true"/> info@laboratorians.net
                                    </a>
                                    {/*<a href={void (0)}>*/}
                                    {/*    <strong>Hotline:</strong> +880 01703 352535*/}
                                    {/*</a>*/}
                                </div>
                            </div>

                            <div className="col-lg-8 col-sm-5 col-5 text-right">
                                <div className="preheader-right">
                                    {
                                        loggedIn ? <>
                                                {
                                                    isAuthLoading ?
                                                        ""
                                                        :
                                                        <>
                                                            {
                                                                isProfile ?
                                                                    <Link className="btn-auth btn-auth login-btn"
                                                                          to={path.profile}>
                                                                        Profile
                                                                    </Link>
                                                                    :
                                                                    <Link className="btn-auth btn-auth login-btn"
                                                                          to={path.complete_registration}>
                                                                        Complete Registration
                                                                    </Link>
                                                            }
                                                        </>
                                                }
                                                <Link
                                                    className="btn-auth btn-auth-rev signup-btn"
                                                    style={logoutLoader ? {cursor: "not-allowed", opacity: "0.7"} : {}}
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </Link>
                                            </> :
                                            <>
                                                <Link
                                                    className="btn-auth btn-auth-rev login-btn"
                                                    to={path.login}
                                                >
                                                    Login
                                                </Link>
                                                <Link className="btn-auth btn-auth signup-btn" to={path.register}>
                                                    Signup
                                                </Link>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={addClass ? "off-canvas active" : "off-canvas"} onClick={() => setAddClass(!addClass)}/>
                <div className={addClass ? "header-bottom-area active" : "header-bottom-area"} id="fixheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="main-menu navbar navbar-expand-lg navbar-light">
                                    <Link className="navbar-brand" to={path.home}>
                                        <img src={logo1} alt="Logo"/>
                                    </Link>
                                    <div className={addClass ? "mobile-nav-icon active" : "mobile-nav-icon"}
                                         onClick={() => setAddClass(!addClass)}>
                                        <div className="div">
                                            <span/>
                                            <span/>
                                            <span/>
                                        </div>
                                    </div>
                                    <div id="menucontent"
                                         className={addClass ? "collapse navbar-collapse mobile-menu-sidebar active" : "collapse navbar-collapse mobile-menu-sidebar"}>
                                        <ul className="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <NavLink exact
                                                         activeClassName="current selected"
                                                         className="nav-link"
                                                         onClick={navActiveRemove}
                                                         to={path.home}>
                                                    Home
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink exact
                                                         activeClassName="current selected"
                                                         className="nav-link"
                                                         onClick={navActiveRemove}
                                                         to={path.about_school}>
                                                    Our School
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink exact
                                                         activeClassName="current selected"
                                                         className="nav-link"
                                                         onClick={navActiveRemove}
                                                         to={path.about_us}>
                                                    About OLsA
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    activeClassName="current selected"
                                                    className="nav-link"
                                                    onClick={navActiveRemove}
                                                    to={path.forum + "?page=1"}>
                                                    Forum
                                                </NavLink>
                                            </li>
                                            {
                                                loggedIn && (
                                                    <li className="nav-item">
                                                        <NavLink exact
                                                                 activeClassName="current selected"
                                                                 className="nav-link"
                                                                 onClick={navActiveRemove}
                                                                 to={path.event}>
                                                            Events
                                                        </NavLink>
                                                    </li>
                                                )
                                            }
                                            {
                                                loggedIn && (
                                                    <li className="nav-item">
                                                        <NavLink exact
                                                                 activeClassName="current selected"
                                                                 className="nav-link"
                                                                 onClick={navActiveRemove}
                                                                 to={path.product}>
                                                            Products
                                                        </NavLink>
                                                    </li>
                                                )
                                            }
                                            {
                                                loggedIn ?
                                                    <>
                                                        {
                                                            isAuthLoading ?
                                                                ""
                                                                :
                                                                <>
                                                                    {
                                                                        isProfile ? <li className="nav-item">
                                                                            <NavLink exact
                                                                                     activeClassName="current selected"
                                                                                     onClick={navActiveRemove}
                                                                                     className="nav-link profile_link"
                                                                                     to={path.profile_search}>
                                                                                Search
                                                                            </NavLink>
                                                                        </li> : ''
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                    : ''
                                            }
                                            <li className="nav-item">
                                                <NavLink exact
                                                         activeClassName="current selected"
                                                         onClick={navActiveRemove}
                                                         className="nav-link" to={path.contact_us}>
                                                    Contact Us
                                                </NavLink>
                                            </li>

                                        </ul>
                                        <div className="mobile-login-logout-btn">
                                            {
                                                loggedIn ? <>
                                                        {
                                                            isAuthLoading ?
                                                                ""
                                                                :
                                                                <>
                                                                    {
                                                                        isProfile ?
                                                                            <Link
                                                                                className="nav-link"
                                                                                onClick={navActiveRemove}
                                                                                to={path.profile}>
                                                                                Profile
                                                                            </Link>
                                                                            :
                                                                            currentPath !== path.complete_registration ?
                                                                                <Link
                                                                                    to={path.complete_registration}
                                                                                    onClick={navActiveRemove}
                                                                                >
                                                                                    Complete Registration <i
                                                                                    className="fa fa-user-o"
                                                                                    aria-hidden="true"/>
                                                                                </Link> :
                                                                                ""
                                                                    }
                                                                </>
                                                        }

                                                        <Link to={void 0}
                                                              style={logoutLoader ? {
                                                                  cursor: "not-allowed",
                                                                  opacity: "0.7"
                                                              } : {}}
                                                            //   onClick={handleLogout}
                                                              onClick={(event) => {
                                                                  handleLogout(event);
                                                                  navActiveRemove()
                                                              }}

                                                        >
                                                            Logout <i className="fa fa-sign-in" aria-hidden="true"/>
                                                        </Link>
                                                    </> :
                                                    <>
                                                        <Link
                                                            to={path.login}
                                                            onClick={navActiveRemove}
                                                        >
                                                            Login
                                                        </Link>
                                                        <Link to={path.register}
                                                              onClick={navActiveRemove}
                                                        >
                                                            Signup
                                                        </Link>
                                                    </>
                                            }
                                        </div>
                                        <div className="mobile-gmail">
                                            <a href="mailto:info@laboratorians.net" style={{fontFamily: "'Roboto'"}}>
                                                <i className="fa fa-envelope-o"
                                                   aria-hidden="true"/> info@laboratorians.net
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalBootstrap
                    show={showWarningModal}
                    handleClose={() => {
                        setShowWarningModal(false)
                        setModalTitle("");
                        setModalData("")
                    }}
                    size={"md"}
                    title={modalTitle}
                    className="blog-forum-modal-bootstrap-title"
                >
                    <div className="card-body">
                        <p className="card-text">{modalData}</p>
                    </div>
                </ModalBootstrap>
            </header>

            {
                loggedIn && (
                    (responseData.membership === membershipValues.GENERAL_MEMBER && new Date().getMonth() === 11) ?
                        <div className="alert alert-warning text-center">
                            <div><i className="fa fa-warning"/> Your membership will be expired on 31st December. Please
                                change your membership from your profile.
                            </div>
                        </div>
                        : responseData.membership === membershipValues.NON_MEMBER ?
                        <div className="alert alert-warning text-center">
                            <div><i className="fa fa-warning"/> Your membership has expired. Please change your
                                membership from your profile.
                            </div>
                        </div> : null
                )
            }

            {
                loggedIn && (
                    <MyCart/>
                )
            }
        </>
    );
};
